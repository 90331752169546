import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import logo from './logo.svg'; 

const theme = createTheme();

const App = () => {
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const requestTokens = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://testnet-faucet.applayer.com/request-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ address: address })
      });
      const data = await response.json();
      setStatus(data.message);
    } catch (error) {
      setStatus(`Error: ${error.message}`);
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CssBaseline />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={logo} alt="Logo" style={{ width: '150px', height: 'auto', marginBottom: '20px' }} />
          <Typography component="h1" variant="h5" color={"white"}>
            Request AppLayer Testnet Tokens
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="address"
            label="Wallet Address"
            name="address"
            autoComplete="address"
            autoFocus
            value={address}
            onChange={e => setAddress(e.target.value)}
            sx={{
              "& label": {
                color: 'white',
              },
              "& label.Mui-focused": {
                color: 'white',
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: 'white',
                },
                "&:hover fieldset": {
                  borderColor: 'white',
                },
                "&.Mui-focused fieldset": {
                  borderColor: 'white',
                },
                "& input": {
                  color: 'white',
                },
              }
            }}
          />
          {loading ? <CircularProgress /> : <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={requestTokens}
          >
              Request Tokens
          </Button>}
          <Typography variant="body2" color="textSecondary" align="center">
            {status}
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
